import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/kids.jpg"
import AppStore from "../../images/appStore.png"
import GooglePlay from "../../images/GooglePlay.jpg"
import Layout from "../../components/layoutPhoenixCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"
import KidsContent from "../../components/kidsContent"

const BadgeImage = styled.img`
  width: 160px;
  margin-top: 2rem;
  margin-right: 1rem;
`

const MainButton = styled.a`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  &:empty {
    display: none;
  }
  @media (max-width: 640px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    text-align: center;
  }
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Cross Church Kids"
    SecondText="Every Weekend. Every Service."
  >
    <SEO title="Cross Church Kids" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      What to Expect
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Welcome to The Cross Church Kids Ministry where everything we do is
      focused on coming alongside you, as the parent, to lead children to a
      growing relationship with Jesus Christ. In this ministry, our children 6
      weeks to 6th grade experience a great time at church with a space,
      activities, music and Bible stories designed just for them.
    </ParagraphText>
    <HeaderText>Preschool @ Cross (Birth - Kindergarten)</HeaderText>
    <ParagraphText>
      Preschool @ Cross is our weekly environment created especially for your
      infants (six weeks) through Kindergarten age children. We know things are
      busy at this stage and your children are learning and growing in amazing
      ways. We can’t wait to meet them.
      <br />
      <br />
      We believe in an active, hands-on approach to learning with children. So,
      expect to see a lot of jumping, singing, and laughter as they learn about
      God. Before kids move into 1st grade, we want them to make sure three
      things stick:
      <br />
      <br />
      God MADE me.
      <br />
      God LOVES me.
      <br />
      Jesus wants to be my FRIEND forever.
    </ParagraphText>
    <HeaderText>Cross Church Kids (1st - 6th Grade)</HeaderText>
    <ParagraphText>
      Cross Church Kids is our weekly environment created especially for 1st - 6th
      grade. Kids dive-in each week in our specially designed space where we
      believe the Bible should never be boring, worship can be loud and fun, and
      children connect with group leaders to learn how God’s word fits into
      their everyday lives.
    </ParagraphText>
    <HeaderText>Parent Cue App</HeaderText>
    <ParagraphText>
      Our goal for Cross Church Kids is so that we can partner with parents to
      help in all stages of life. One way to do that is to download the Parent
      Cue App that follows our curriculum and gives helpful tips at all stages
      of kids' life.
    </ParagraphText>
    <div
      css={css`
        display: flex;
      `}
    >
      <a href="https://itunes.apple.com/us/app/parent-cue/id433066482?mt=8">
        <BadgeImage src={AppStore} alt="Apple Store Icon" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=org.rethinkgroup.parentcuepaid&hl=en">
        <BadgeImage src={GooglePlay} alt="Google Play icon" />
      </a>
    </div>
  </Layout>
)

export default IndexPage
